import * as React from "react";
import { useState } from "react";
import { Badge, Dialog, Box, Table, TableHead, TableRow, TableCell, TableBody, DialogContent } from "@material-ui/core";
import NotificationsIcon from "@material-ui/icons/Notifications";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../reducers/rootReducer";
import { ErrorI } from "../../interfaces/errors";
import { INotificationLogSlice } from "../../slices/notificationLogSlice"
import {isTMTCameraDeviation, getDateTime, getEventDetails, notificationHeader } from "../../utilities/helperFunctions";
import { Link } from "react-router-dom";
import { setCurrentFurnaceId } from "../../slices/scenarioSimulatorSlice";
import { setFurnaceId,setFurnaceOpMode } from "../../slices/furnaceSlice";
import { getCameraByCameraId, getFurnaceByFurnaceId } from "../../configuration";
import { setCameraNameForCameraStatus, setFurnaceNameForCameraStatus } from "../../slices/cameraStatusSlice";
import { setCurrentCamera } from "../../slices/cameraSlice";
import "../../assets/styles/_notification_log.scss"

export interface NotificationProps {}

/**
 * Notification component is used to give user information whenever something unusual happens during calculation on Feedstock
 */

const Notification: React.SFC<NotificationProps> = () => {
  const [showPopUp, setShowPopUp] = useState(false);
  const { errors }: { errors: ErrorI[] } = useSelector((state: RootState) => state.errorsState);
  const { notificationLog }: {notificationLog: INotificationLogSlice[]}   = useSelector((state: RootState) => { return state.notificationState});
  const dispatch = useDispatch()
    
  const errorHeader = ["Date", "Source", "Message", "Error Code"]
  
  const showAllNotificationsOnClick = () => {
    setShowPopUp(false)
  }

  const onRedirect = (furnace_id,op_mode,log) => {
    setShowPopUp(false)
    dispatch(setCurrentFurnaceId(furnace_id))
    dispatch(setFurnaceId(furnace_id))
    dispatch(setFurnaceOpMode(op_mode))
    let furnaceName = getFurnaceByFurnaceId(parseInt(furnace_id));
    dispatch(setFurnaceNameForCameraStatus(furnaceName));
    let cameraName = getCameraByCameraId((log['camera_id']));
    dispatch(setCameraNameForCameraStatus(cameraName));
    dispatch(setCurrentCamera(cameraName));
  }

  const isTMTDeviation = (rule_id) => {
    if((rule_id === 1) || (rule_id === 2) || (rule_id === 3)){
      return true
    }
    return false
  }

  return (
    <React.Fragment>
      <Badge overlap="rectangular" badgeContent={isTMTCameraDeviation() ? notificationLog.length : errors.length} style={{ visibility: ((notificationLog.length === 0) && (errors.length === 0)) ? "hidden" : "inherit", marginRight: "20px" }} color="primary" invisible={(( notificationLog.length === 0) && (errors.length === 0))} onClick={() => setShowPopUp(true)}>
        <NotificationsIcon style={{ color: "red", visibility: (notificationLog.length === 0 && errors.length === 0) ? "hidden" : "inherit" }} />
      </Badge>
      <Dialog open={showPopUp} onClose={() => setShowPopUp(false)} maxWidth={false} scroll="paper">
        <DialogContent>
          <Box>
            <Table size="small" className="notification-log">
              <TableHead>
                <TableRow>
                  {errors && errors.length > 0 && errorHeader.map((header) => (
                    <TableCell key={header} className="bold">
                      {header}
                    </TableCell>
                  ))}
                  {isTMTCameraDeviation() && notificationLog && notificationLog.length > 0 && notificationHeader.map((header) => (
                    <TableCell key={header} className="bold">
                      {header}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {errors && errors.length > 0 && errors.map((err) => (
                  <TableRow key={JSON.stringify(err)}>
                    <TableCell>{err.date}</TableCell>
                    <TableCell>{err.source}</TableCell>
                    <TableCell>{err.message}</TableCell>
                    <TableCell>{err.errorCode || "-"}</TableCell>
                  </TableRow>
                ))}
                {isTMTCameraDeviation() && notificationLog && notificationLog.length > 0 && notificationLog.slice(0, 10).map((log) => (                
                  <TableRow key={JSON.stringify(log)}>
                    <TableCell>
                      {isTMTDeviation(log['rule_id']) ? 
                        <Link onClick={() => onRedirect(log['furnace_id'],log['operating_mode'],log)} to={'/multi-camera'}>{log['event_type']}</Link>    
                        :
                        <Link onClick={() => onRedirect(log['furnace_id'],log['operating_mode'],log)} to={'/camera-status/'}>{log['event_type']}</Link>               
                      }                 
                    </TableCell>
                    <TableCell>{log['rule_name']}</TableCell>
                    <TableCell>{getDateTime(log['timestamp'])}</TableCell>
                    <TableCell>{getFurnaceByFurnaceId(log['furnace_id'])}</TableCell>
                    <TableCell>{log['camera_id']}</TableCell>
                    <TableCell>{log['operating_mode']}</TableCell>
                    <TableCell>{log['equipment_level']}</TableCell>
                    <TableCell className={log['rule_severity'] === 'High' ? 'high-severity' : 'medium-severity'}>{log['rule_severity']}</TableCell>  
                    <TableCell>{log['rule_category']}</TableCell>                 
                    <TableCell>{getEventDetails(log['rule_id'], getFurnaceByFurnaceId(log['furnace_id']), log['coilgroup_name'], log['coilgroup_name_max'], log['coilgroup_name_min'], log['camera_id'],log['camera_temperature'])}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <div className="notification-buttons">
              {isTMTCameraDeviation() && notificationLog && notificationLog.length > 0 &&
                <Link onClick={showAllNotificationsOnClick} className="button is-primary" style={{ width: "100%", marginTop: "16px", marginRight: "10px" }} to={'/notification-log'}> Show more </Link>
              }
              <button onClick={() => setShowPopUp(false)} className="button is-primary" style={{ width: "100%", marginTop: "16px" }}>
                Close
              </button>
            </div>
          </Box>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default Notification;
