import {Table, TableRow, TableCell, TableBody, Paper, TextField, Grid, Box } from "@material-ui/core"
import { withStyles, WithStyles } from '@material-ui/core/styles';
import React, { Component } from "react"
import Loader from "react-spinners/ClipLoader";
import { connect, ConnectedProps } from "react-redux"
import { RootState } from "../../reducers/rootReducer"
import store from "../..";
import { REACT_APP_APIM_URL } from "../../utilities/GlobalConstants";
import { tmtStyles } from "./TmtInformationStyle";
import "../../assets/styles/_thermalImageInformationBlock.scss";
import FurnaceSelector from "./FurnaceSelector";
import { ICameras } from "../../slices/cameraSlice";
import { getSelectedCameraDetails, getCoilgroups, getTemperatureUnit } from "../../configuration";
import { setCoilgroups } from '../../slices/coilGroupSlice'
import { isTMTCameraDeviation } from "../../utilities/helperFunctions";

interface ITmtInformationBlock extends WithStyles<typeof tmtStyles>{
}

interface ICameraSelctorProps {
    cameraData: ICameras[];
}

const mapStateToProps = (state: RootState) => {
    return {
        selectedCoilgroupImageName: state.coilGroup.selectedCoilgroupImageName,
        currCamera: state.camera.name,
        selectedDate: state.coil.selectedDate,
        coilGroups: state.coilGroup.coilGroups,
        furnaces: state.furnace,
        currentCameraId: state.camera.id,
        accessibleFeatures: state.features.accessibleFeatures
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setCoilgroups: (values: string[]) => dispatch(setCoilgroups(values))
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>;

type TmtInformationBlockReduxProps = PropsFromRedux & ITmtInformationBlock & ICameraSelctorProps;

type TmtInformationBlockState = {
    selectedCoilgroupImageName : string
    currCamera : string,
    selectedDate : Date,
    coil_details : {}
    max_maxtmt_over_furnacecell : string,
    tmt_threshold : string,
    tmt_threshold_overshoot_coilgroups : string[],
    coilGroups : string[],
    tmt_threshold_overshoot_frunacecell : string[],
    tmtBlockLoader : boolean,
    currentCameraId : string
}
class TmtInformationBlock extends Component<TmtInformationBlockReduxProps, TmtInformationBlockState>{

    constructor(props) {
        super(props);

        this.state = {
            selectedCoilgroupImageName: this.props.selectedCoilgroupImageName,
            currCamera: this.props.currCamera,
            selectedDate: this.props.selectedDate,
            coil_details : {},
            max_maxtmt_over_furnacecell : "",
            tmt_threshold : "",
            tmt_threshold_overshoot_coilgroups : [],
            coilGroups : this.props.coilGroups,
            tmt_threshold_overshoot_frunacecell : [],
            tmtBlockLoader : true,
            currentCameraId : this.props.currentCameraId
        }
    }

    getTMTThreshold = () => {
        // let out = this.props.cameraData?.filter((camera, i) => {
        //     if(this.state.currCamera == camera.camera_name){
        //         return camera.tmt_threshold_value     
        //     }                      
        // })
        // this.setState({tmt_threshold: out[0] ? out[0].tmt_threshold_value : "0"})
        let selectedCameraDetails = getSelectedCameraDetails(parseInt(this.props.currentCameraId));
        this.setState({tmt_threshold: selectedCameraDetails['tmt_threshold_value']})
    }

    getTMTInformation = () => {
        let newDate = ''
        if(this.props.selectedDate){
            let dateImg = new Date(this.props.selectedDate);
            newDate = dateImg.getFullYear() + "-" + ('0' + (dateImg.getMonth() + 1)).slice(-2) + "-" + ('0' + dateImg.getDate()).slice(-2) + "T" + ('0' + dateImg.getHours()).slice(-2) + ":" + ('0' + dateImg.getMinutes()).slice(-2)
            fetch(`${REACT_APP_APIM_URL}/thermalimage/getTMTInformation/${newDate}`, {
                method: "GET",
                headers: {
                    Accept: "*/*",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + store.getState().authState.token,
                    "Access-Control-Allow-Origin": "*"
                },
            })
            .then((res) => res.json())
            .then(
                (resp) => {
                    let coilGroups = getCoilgroups(this.props.furnaces.current_furnace_id);
                    coilGroups = coilGroups && coilGroups.filter((cg,i, cgs) => cgs.indexOf(cg) === i)
                    const furnaceCoilGroups: any[] = [];
                    coilGroups.forEach(x => {
                        furnaceCoilGroups.push(x.name)
                    });
                    this.props.setCoilgroups(furnaceCoilGroups)
                    let allCoilGroups = this.props.coilGroups
                    /*max of max_tmt over furnace cell start*/
                    let max_values = {}
                    let min_values = {}
                    this.getTMTThreshold();
                    let tmt_threshold = this.state.tmt_threshold;
                    allCoilGroups.forEach(function (item, index) {
                        max_values[resp[item+"_maxCoilNumber"]] = resp[item+"_tmt_max"]
                        min_values[resp[item+"_minCoilNumber"]] = resp[item+"_tmt_min"]
                    });
                    let max_maxtmt_over_furnacecell = Object.keys(max_values).reduce((a, b) => max_values[a] > max_values[b] ? a : b);
                    let min_maxtmt_over_furnacecell = Object.keys(min_values).reduce((a, b) => min_values[a] < min_values[b] ? a : b);
                    /*max of max_tmt over furnace cell end*/
                    /*tmt threshold overshoot for specific coil group start*/
                    let thresholdDifferenceCoilgroups : string[] = [];
                    
                    allCoilGroups.forEach(function (item, index) {
                        var thresholdDifference = Math.abs(resp[item+"_tmt_max"] - resp[item+"_tmt_min"])
                        if(thresholdDifference > parseInt(tmt_threshold))
                        {
                            thresholdDifferenceCoilgroups.push(item)
                        }
                    });
                    /*tmt threshold overshoot for specific coil group end*/
                    /*tmt threshold overshoot for furnace cell start*/
                    let max_maxtmt_val = max_values[max_maxtmt_over_furnacecell]
                    let min_mintmt_val = min_values[min_maxtmt_over_furnacecell]
                    let thresholdDifferenceFurnaceCell : string[] = []

                    if(Math.abs(max_maxtmt_val - min_mintmt_val) > parseInt(this.state.tmt_threshold))
                    {
                        thresholdDifferenceFurnaceCell.push(max_maxtmt_over_furnacecell, min_maxtmt_over_furnacecell)
                    }
                    /*tmt threshold overshoot for furnace cell end*/
                    let coilDetails = []
                    allCoilGroups.forEach(function (item, index) {
                        coilDetails[item]={"tmt_min":resp[item+"_tmt_min"], "tmt_max":resp[item+"_tmt_max"], "minCoilNumber":resp[item+"_minCoilNumber"], "maxCoilNumber":resp[item+"_maxCoilNumber"],};
                    })

                    this.setState({
                        coil_details: coilDetails,
                        max_maxtmt_over_furnacecell: max_maxtmt_over_furnacecell,
                        tmt_threshold_overshoot_coilgroups: thresholdDifferenceCoilgroups,
                        tmt_threshold_overshoot_frunacecell: thresholdDifferenceFurnaceCell,
                        tmtBlockLoader: false
                    })
                },
                (error) => console.log("error", error)
            )
        }
    }

    componentDidMount(): void {
        this.getTMTInformation()
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.selectedDate !== this.props.selectedDate || prevState.tmt_threshold !== this.state.tmt_threshold || this.props.currCamera !== this.state.currCamera) {
            this.getTMTInformation()
        }
    }

    render(): React.ReactNode {
        
        return (
            <>
            {this.props.coilGroups.length > 0 ?
            <Paper elevation={3} className='TMTInfoBlock'>
                <div className="control-header element tmtgen">
                    <div className="container-typography tmtgen">                        
                        <Grid item direction="row" justifyContent="flex-start" xs={8} lg={10} xl={8} className="gen_display">
                                    <span className="cell_spacing tmt_furnace_header furnace_summary">Furnace Summary</span>
                                    <FurnaceSelector fromTMT="fromTMT"  />
                                    <span className="cell_spacing tmt_furnace_header">Run length(d)</span>
                                    <span className="cell_spacing tmt_furnace_value">N/A</span>
                                    <span className="cell_spacing tmt_furnace_header">Furnace Mode</span>
                                    <span className="cell_spacing tmt_furnace_value">{isTMTCameraDeviation() ? ((this.props.furnaces.furnace_metrics['State']) || (this.props.furnaces.furnace_op_mode)) : this.props.furnaces.furnaces[0].State}</span>
                        </Grid>
                    </div>
                </div>
                { this.state.tmtBlockLoader 
                    ?
                    <div className="loader-grid-parent">
                        < div className="loader-grid">
                        <Loader />
                        <p> Loading...</p>
                        </div>
                    </div>
                : 
                <>
                    <Box width="100%" display="flex" justifyContent="left" alignItems="left">
                        <Grid item className="tmtBlockTable">
                            <Table className="tmt-table" >
                                <TableBody>
                                    <TableRow className="legend_border tmt_coil_bg">
                                        <TableCell className="legend_border cell_spacing bold th"></TableCell>
                                        {this.props.coilGroups.map((coil, i) => (
                                            <TableCell className="legend_border cell_spacing bold th" align="center" style={this.state.tmt_threshold_overshoot_frunacecell.some(str => str.includes(coil)) ? {backgroundColor: '#FFF241',color: "#435591"}:{color: "#435591"}} colSpan={2}>Coilgroup {coil}</TableCell>
                                        ))}
                                        <TableCell className="legend_border th cell_spacing tmt_white_bg" align="left" >Max of maxTMT over furnance Cell:</TableCell><TableCell className="legend_border bold th cell_spacing tmt_furn_bg" align="left"></TableCell>
                                    </TableRow>
                                    <TableRow className="legend_border tmt_bl_bg">
                                        <TableCell align="center" className="legend_border cell_spacing bold th tmt_lbl_bg">maxTMT</TableCell>
                                        {this.props.coilGroups.map((coil, i) => (
                                            <>
                                                <TableCell align="center" className="legend_border cell_spacing bold th tmt_lbl_bg">Value ({getTemperatureUnit()})</TableCell>
                                                <TableCell align="center" className="legend_border cell_spacing bold th tmt_lbl_bg">Coil Number</TableCell>
                                            </>
                                        ))}
                                        <TableCell className="legend_border th cell_spacing tmt_white_bg" align="left">TMT Threshold overshoot for specific CoilGroup:</TableCell><TableCell className="legend_border bold th cell_spacing tmt_red_bg" align="left"
                                        ></TableCell>
                                    </TableRow>
                
                                    <TableRow className="legend_border tmt_coil_bg">
                                        <TableCell align="center" className="legend_border cell_spacing bold th tmt_lbl_bg">minimum</TableCell>
                                        {this.props.coilGroups.map((coil, i) => (
                                            <>
                                                <TableCell align="center" className="legend_border cell_spacing th" style={this.state.tmt_threshold_overshoot_coilgroups.includes(coil) ? { backgroundColor:'#ed7925'} : {}}>{this.state.coil_details[coil]?.tmt_min}</TableCell>
                                                <TableCell align="center" className="legend_border cell_spacing th">{this.state.coil_details[coil]?.minCoilNumber}</TableCell>
                                            </>
                                        ))}
                                        <TableCell className="legend_border cell_spacing tmt_white_bg" align="left" >TMT Threshold overshoot for Furnace Cell:</TableCell><TableCell className="legend_border bold th cell_spacing tmt_yel_bg" align="left"></TableCell>
                                    </TableRow>
                                    <TableRow className="legend_border tmt_bl_bg">
                                        <TableCell align="center" className="legend_border cell_spacing bold th tmt_lbl_bg">maximum</TableCell>
                                        {this.props.coilGroups.map((coil, i) => (
                                            <>
                                                <TableCell align="center" className="legend_border cell_spacing th" style={this.state.tmt_threshold_overshoot_coilgroups.includes(coil) ? { backgroundColor:'#ed7925'} : {}}>{this.state.coil_details[coil]?.tmt_max}</TableCell>
                                                <TableCell align="center" className="legend_border cell_spacing th" style={this.state.coil_details[coil] && this.state.coil_details[coil]?.maxCoilNumber === this.state.max_maxtmt_over_furnacecell && this.state.max_maxtmt_over_furnacecell !== "" ? { backgroundColor:'#F45858'} : {}}>{this.state.coil_details[coil]?.maxCoilNumber}</TableCell>
                                            </>
                                        ))}
                                        <TableCell className="legend_border cell_spacing tmt_white_bg" align="left" >TMT Threshold:</TableCell>
                                        <TextField onChange={(e) => this.setState({tmt_threshold: e.target.value})} value={this.state.tmt_threshold+getTemperatureUnit()} disabled/>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Grid>
                    </Box>
                </>
                }
                </Paper> : <></>}
            </>
        )
    }
}

export default connector(withStyles(tmtStyles)(TmtInformationBlock))