import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type INotificationLogSlice = {
    notificationLog: Array<any>
}

const initialState: INotificationLogSlice = {
    notificationLog: []
}

export const notificationLogSlice = createSlice({
    name: 'notificationLogSlice',
    initialState: initialState,
    reducers: {
        setNotificationLog: (state, action: PayloadAction<any[]>) => {
            state.notificationLog = action.payload;
        },
        resetNotificationLog: (state) => {
            state.notificationLog = initialState.notificationLog
        }
    }
})

export const {
    setNotificationLog,
    resetNotificationLog
} = notificationLogSlice.actions


export default notificationLogSlice.reducer