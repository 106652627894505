import store from "..";
import { authProvider } from "../components/authProvider";
import { getProductPredictorGroupDetailLabel } from "../configuration";
import { ErrorI } from "../interfaces/errors";
import { UNIT } from "../interfaces/IComponent";
import { cosy_preperties } from "../interfaces/IConvectionSection";
import { FEEDSTOCK_STATUS, FEEDSTOCK_TYPES, IFeedstock, IShortPiona } from "../interfaces/IFeedstock";
import { FUELGAS_STATUS, IFuelGas } from "../interfaces/IFuelGas";
import { IProductPredictorFeedstockDetail, IProductPredictorGroup, IProductPredictorOutputProduct, IProductPredictorScenario } from "../interfaces/IProductPredictor";
import { CALCULATION_STATUS, ERROR_TYPE, SCENARIO_PROGRESS, SCENARIO_STATUS, SPEC_TYPE, TERMINATION_REASON } from "../interfaces/IScenario";
import { normalizeProductPredictorFeedstock } from "../slices/feedstockSlice";
import { normalizeFuelGas } from "../slices/fuelGasSlice";
import { resetOutput, setPPResult, setPPScenarioCalculationStatus, setPPScenarioProgress, setPPScenarioStatus, setProgress, setTerminationReason, updatePPScenarioCaseId } from "../slices/productPredictorSlice";
import { REACT_APP_APIM_URL_COMPUTE } from "./GlobalConstants";
import { getFactor } from "./helperFunctions";
const { ServiceBusClient } = require("@azure/service-bus");

export async function init_calculations(scenario, token: string) {
    store.dispatch(setPPScenarioStatus({ scenario_id: scenario.id, status: SCENARIO_STATUS.RUNNING }));
    store.dispatch(updatePPScenarioCaseId({ scenario_id: scenario.id, caseId: "" }));
    await scenarioSimulation(scenario, token);
}

export async function scenarioSimulation(scenario: IProductPredictorScenario, token: string) {
    store.dispatch(setPPScenarioProgress({ scenario_id: scenario.id, status: SCENARIO_PROGRESS.VALIDATION }));
    store.dispatch(setProgress({ scenario_id: scenario.id, progress: Number(0) }))
    const fuelGas_index = scenario.fuelgas_id;
    if (!fuelGas_index) {
        const error: ErrorI = {
            date: new Date().toLocaleString(),
            source: "FUELGAS",
            message: `No fuelGas defined for #${scenario.name}`,
        };
        store.dispatch({ type: "ADD_ERROR", payload: { value: error } });
        store.dispatch(setPPScenarioStatus({ scenario_id: scenario.id, status: SCENARIO_STATUS.ERROR }));
        store.dispatch(setTerminationReason({ scenario_id: scenario.id, reason: TERMINATION_REASON.IDLE }))
        return;
    }
    let isGroupConfigured = scenario.groups.every(group => {
        if (!group.modified) {
            const error: ErrorI = {
                date: new Date().toLocaleString(),
                source: "GROUP CONFIGURATION",
                message: `No Group Configuratio defined for Group: #${group.grouplabel} furnace - Scenario: #${scenario.name}`,
            };
            store.dispatch({ type: "ADD_ERROR", payload: { value: error } });
            store.dispatch(setPPScenarioStatus({ scenario_id: scenario.id, status: SCENARIO_STATUS.ERROR }));
            store.dispatch(setTerminationReason({ scenario_id: scenario.id, reason: TERMINATION_REASON.IDLE }));
            return false;
        }
        return true
    })


    const totalFurnaceNum = scenario.groups.reduce((prev, grp) => prev + Number(grp.furnace_number), 0)


    if (!isGroupConfigured)
        return;

    let inputs: any[] = [];
    scenario.groups.forEach(group => {
        if (isCalculationStoppedDueToError(scenario.id)) {
            return;
        }


        //Reset Errors
        store.dispatch({ type: "RESET_ERRORS" });
        const convectionsection_index = group.convectionsection_id;
        let feed_error: any;
        let isFeedstockValid = group.feedstock?.some(x => {
            if (!x.feedstock_id) {
                const error: ErrorI = {
                    date: new Date().toLocaleString(),
                    source: "FEEDSTOCK",
                    message: `No feedstock defined for Group: #${group.grouplabel} furnace - Scenario: #${scenario.name}`,
                };
                feed_error = error
                return false;
            }
            return true;
        })
        if (!isFeedstockValid) {
            store.dispatch({ type: "ADD_ERROR", payload: { value: feed_error } });
            store.dispatch(setPPScenarioStatus({ scenario_id: scenario.id, status: SCENARIO_STATUS.ERROR }));
            store.dispatch(setTerminationReason({ scenario_id: scenario.id, reason: TERMINATION_REASON.IDLE }));
            return;
        }

        //Furnace Number Validation
        if (totalFurnaceNum > 8 || totalFurnaceNum < 3 || group.furnace_number > group.maxFurnace_number! || group.furnace_number < 1) {
            const error: ErrorI = {
                date: new Date().toLocaleString(),
                source: "INVALID FURNACE NUMBER(S)",
                message: `Maximum allowable furnace number is (8) for - Scenario: #${scenario.name}`,
            };
            store.dispatch({ type: "ADD_ERROR", payload: { value: error } });
            store.dispatch(setPPScenarioStatus({ scenario_id: scenario.id, status: SCENARIO_STATUS.ERROR }));
            store.dispatch(setTerminationReason({ scenario_id: scenario.id, reason: TERMINATION_REASON.IDLE }));
            return;
        }

        // Reset errors and states
        store.dispatch(resetOutput({ scenario_id: scenario.id }));
        store.dispatch(setTerminationReason({ scenario_id: scenario.id, reason: TERMINATION_REASON.IDLE }))

        // Set scenario status to calculating
        store.dispatch(setPPScenarioCalculationStatus({ scenario_id: scenario.id, status: CALCULATION_STATUS.INIT }))

        // Normalize data
        normalizeInput(group.feedstock!, fuelGas_index);

        //fuelgas
        let fuelGas_index2 = store.getState().fuelGas.fuelGases.findIndex(feedstock => feedstock.id === scenario.fuelgas_id);
        const normalized_fuelGas = store.getState().fuelGas.fuelGases[fuelGas_index2];
        if (normalized_fuelGas.status === FUELGAS_STATUS.ERROR) {
            const error: ErrorI = {
                date: new Date().toLocaleString(),
                source: "FUELGAS",
                message: `Error in fuelgas #${normalized_fuelGas.name} for scenario: #${scenario.name}`,
            };
            store.dispatch({ type: "ADD_ERROR", payload: { value: error } });
            store.dispatch(setPPScenarioStatus({ scenario_id: scenario.id, status: SCENARIO_STATUS.ERROR }));
            store.dispatch(setTerminationReason({ scenario_id: scenario.id, reason: TERMINATION_REASON.IDLE }));
            return;
        }

        //feedstock
        let input_components: any = []
        group.feedstock?.forEach(ele => {
            let feedstock_index = store.getState().feedstock.feedstocks.findIndex(feedstock => feedstock.id === ele.feedstock_id);
            let normalized_feedstock;
            if (feedstock_index > -1) {
                normalized_feedstock = store.getState().feedstock.feedstocks[feedstock_index];
                if (normalized_feedstock.status === FEEDSTOCK_STATUS.ERROR) {
                    const error: ErrorI = {
                        date: new Date().toLocaleString(),
                        source: "FEEDSTOCK",
                        message: `Error in feedstock! #${normalized_feedstock.name} for Group: #${group.grouplabel} furnace - Scenario: #${scenario.name}`,
                    };
                    store.dispatch({ type: "ADD_ERROR", payload: { value: error } });
                    store.dispatch(setPPScenarioStatus({ scenario_id: scenario.id, status: SCENARIO_STATUS.ERROR }));
                    store.dispatch(setTerminationReason({ scenario_id: scenario.id, reason: TERMINATION_REASON.IDLE }));
                    return;
                }
            }
            const Inputs = {
                Inputs: feedstock_index > -1 ? getComponentsForPreCalculation(normalized_feedstock) : {},
                feedstocktype: feedstock_index > -1 ? normalized_feedstock.type : '',
                flowrate: ele.flowrate === -1 ? 0 : ele.flowrate,
                totalflowrate: group.flowrate
            }
            input_components.push(Inputs);
        })

        const input_components_for_precalculation = {
            ComponentInputs: input_components
        }

        const convectionSection = store.getState().convectionsection.convectionsections[convectionsection_index];

        const furnace_type_id = group.furnacetype_id;
        const feed_type_id = group.feedtype_id;

        const scenario_values = getScenarioSettings2(group, scenario.pressure, scenario.prediction_time, normalized_fuelGas, convectionSection, furnace_type_id, feed_type_id);
        const input = { ...input_components_for_precalculation, ...scenario_values };
        inputs.push(input);
    })
    const scenario_index = store.getState().productPredictor.productPredictorScenarios.findIndex(sce => sce.id === scenario.id);
    if (store.getState().productPredictor.productPredictorScenarios[scenario_index].status === SCENARIO_STATUS.RUNNING) {
        if (isCalculationStoppedDueToError(scenario.id)) {
            return;
        }
        const inp = { "Inputs": inputs }
        if (isCalculationStopped(scenario.id)) {
            return;
        }
        let precalculated_values = await computeValidation(inp, token);

        if ("Message" in precalculated_values && precalculated_values.Message.length > 0) {
            let err_msg = `${precalculated_values.Message}`;
            err_msg = err_msg.replace('group1', "Group #" + getProductPredictorGroupDetailLabel('group1')).replace('group2', "Group #" + getProductPredictorGroupDetailLabel('group2')).replace('group3', "Group #" + getProductPredictorGroupDetailLabel('group3'))
            const error: ErrorI = {
                date: new Date().toLocaleString(),
                source: "PRECALC API",
                message: `${err_msg} for Scenario #${scenario.name}`,
            };;
            store.dispatch({ type: "ADD_ERROR", payload: { value: error } });
            store.dispatch(setPPScenarioStatus({ scenario_id: scenario.id, status: SCENARIO_STATUS.ERROR }));
            store.dispatch(setTerminationReason({ scenario_id: scenario.id, reason: TERMINATION_REASON.IDLE }));
            return;
        }

        delete precalculated_values.Message;
        delete precalculated_values.Status;

        precalculated_values.Inputs.forEach(input => delete input.Input.COMPILED_FLOW_HC);

        store.dispatch(setPPScenarioProgress({ scenario_id: scenario.id, status: SCENARIO_PROGRESS.INIT }));
        if (isCalculationStopped(scenario.id)) {
            return;
        }
        const init_computation = await computeInitialize(scenario, precalculated_values, token)

        //Update case_id in scenario 
        store.dispatch(updatePPScenarioCaseId({
            scenario_id: scenario.id,
            caseId: init_computation["scenario_case_id"]?.replace("productpredictor_frontend-", "")
        }));

        if (isCalculationStopped(scenario.id)) {
            const scenario_index = store.getState().productPredictor.productPredictorScenarios.findIndex(sce => sce.id === scenario.id);
            const new_scenario = store.getState().productPredictor.productPredictorScenarios[scenario_index];
            await stopCalculation(new_scenario, token)
            return;
        }

        if (isCalculationStoppedDueToError(scenario.id)) {
            return;
        }

        await sleep(6000);
        store.dispatch(setPPScenarioProgress({ scenario_id: scenario.id, status: SCENARIO_PROGRESS.Looping }));
        await listenServiceBus(init_computation["scenario_case_id"], "FrontendProductPredictorSubscription", init_computation["sas_connection_string"], init_computation["sas_token"], scenario.id)
    }
}


function isCalculationStopped(scenario_id): boolean {
    const scenarios = store.getState().productPredictor.productPredictorScenarios;
    const curr_scenario_index = scenarios.findIndex(s => s.id === scenario_id);
    return scenarios[curr_scenario_index].status === SCENARIO_STATUS.STOP_CALCULATION;
}


function isCalculationStoppedDueToError(scenario_id): boolean {
    const scenarios = store.getState().productPredictor.productPredictorScenarios;
    const curr_scenario_index = scenarios.findIndex(s => s.id === scenario_id);
    return scenarios[curr_scenario_index].status === SCENARIO_STATUS.ERROR;
}

function normalizeInput(feedstocks: IProductPredictorFeedstockDetail[], fuelGas_id: string) {

    store.dispatch(normalizeProductPredictorFeedstock({ feedstocks }));
    store.dispatch(normalizeFuelGas(fuelGas_id));
}

async function computeValidation(input_components_for_precalculation, token: string) {
    const result = await fetch(`${REACT_APP_APIM_URL_COMPUTE}/productpredictor/validation`, {
        method: "POST",
        headers: {
            Accept: "*/*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
            Origin: window.location.origin
        },
        body: JSON.stringify(input_components_for_precalculation),
    });
    return await result.json();
}


async function computeInitialize(scenario, scenario_input, token: string) {

    // Refresh token before calling compute Initialization
    const { accessToken, expiresOn } = await authProvider.getAccessToken();;
    store.dispatch({ type: "ADD_TOKEN", payload: { value: accessToken } });
    store.dispatch({ type: "ADD_EXPIRATION", payload: { value: expiresOn } });

    let check = await fetch(`${REACT_APP_APIM_URL_COMPUTE}/productpredictor/initialize`, {
        method: "POST",
        headers: {
            Accept: "*/*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + accessToken,
            "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify(scenario_input),
    });
    check = await check.json();
    return check;
}


export async function stopCalculation(scenario, token: string) {

    // Refresh token before calling compute Initialization
    const { accessToken } = await authProvider.getAccessToken();
    if (scenario.case_id !== "") {
        let check = await fetch(`${REACT_APP_APIM_URL_COMPUTE}/productpredictor/Stop/${scenario.case_id}`, {
            method: "GET",
            headers: {
                Accept: "*/*",
                "Content-Type": "application/json",
                Authorization: "Bearer " + accessToken,
                "Access-Control-Allow-Origin": "*",
            },
        })
        check = await check.json();
        return check;
    }
}

function getScenarioSettings2(scenario: IProductPredictorGroup, pressure, prediction_time, fuelGas: IFuelGas, convectionSection, furnace_type_id, feed_type_id) {
    let spec_type = SPEC_TYPE.COT
    switch (scenario.SPEC_TYPE) {
        case ("COT"):
            spec_type = SPEC_TYPE.COT
            break;
        case ("P/E Ratio"):
            spec_type = SPEC_TYPE.P_E_RATIO
            break;
        case ("Conversion"):
            spec_type = SPEC_TYPE.CONVERSION
            break;
        default:
            spec_type = SPEC_TYPE.COT
    }
    let key_component = "C2H6"
    switch (scenario.KEY_COMPONENT) {
        case ("Ethane"):
            key_component = "C2H6";
            break;
        case ("Propane"):
            key_component = "C3H8";
            break;
        case ("i-Butane"):
            key_component = "IBUTA";
            break;
        case ("n-Butane"):
            key_component = "NBUTA";
            break;
        case ("i-Pentane"):
            key_component = "ICS";
            break;
        case ("n-Pentane"):
            key_component = "NC5";
            break;
        case ("n-Dekan"):
            key_component = "NC10";
            break;
        case ("n-Pentadekan"):
            key_component = "NC15";
            break;
        case ("n-Eikosan"):
            key_component = "NC20";
            break;
        default:
            key_component = "C2H6";
    }
    const volfraction = fuelGas.components.map(component => component.value.toString());

    let convectiondata = {};
    if (convectionSection) {
        convectiondata["COSY"] = [["True"]];

        cosy_preperties.map(cosy_key => convectiondata[cosy_key] = [[convectionSection.components[0][cosy_key]['design'].toString()]]);
    }
    else {
        convectiondata["COSY"] = [["False"]];
    }
    const CompiledFlowrate = Number(scenario.feedstock!.reduce((prev, feed) => prev + (Number(feed.flowrate) > 0 ? Number((feed.flowrate * getFactor(feed.name))) : 0), 0)) / Number(scenario.furnace_number);
    const scenarioInput = {
        "Input": {
            "SPEC_TYPE": [[spec_type]],
            "SPEC": [[scenario.SPEC.toString()]],
            "KEY_COMPONENT": [[key_component]],
            "FLOW_HC": [[scenario.flowrate.toString()]],
            "COMPILED_FLOW_HC": [[CompiledFlowrate.toString()]],
            "DILUT": [[scenario.DILUT.toString()]],
            "CIT": [[scenario.CIT.toString()]],
            "COP": [[pressure]],
            "VOLFRACTION": [volfraction]
        },
        "UserInput": {
            "furnace_type_id": [[furnace_type_id]],
            "feed_type_id": [[feed_type_id]],
            "Units": ["CEL", "Bar", "kg/h", "Wt%"],
            "PRODUCT_PREDICTOR": [["True"]],
            "FURNACE_NUMBER": [[scenario.furnace_number]],
            "RUNLENGTH_MAX": [[prediction_time.toString()]],
            ...convectiondata
        }
    }

    return scenarioInput;
}

function getComponentsForPreCalculation(feedstock: IFeedstock): any {

    let components: any;
    if (feedstock.type === FEEDSTOCK_TYPES.DISCRETE_COMPOSITION) {
        const non_zero_components = feedstock.feedstock.components.filter(component => component.value > 0);
        const components_backend = {};
        non_zero_components.forEach(component => {
            components_backend[component.name] = component.value;
        });
        components = {
            ComponentInput: {
                Comp: components_backend,
                Frac: feedstock.unit === "MOL %" ? "mol" : "weight"
            },
            Mode: "compmapping"
        }
    }
    else if (feedstock.type === FEEDSTOCK_TYPES.SHORT_PIONA) {
        let obj = feedstock.feedstock.components.filter(c => c.id !== "d1515")
        let ret = {};
        let newObj = Object.keys(obj).map((id, value) => {
            let key = obj[id].id
            let val = Number(obj[value].value)
            ret[key] = val;
            return ret;
        });
        components = {
            ShortPionaInput: {
                "sline_dict": {
                    "stype": (feedstock.feedstock as IShortPiona).method.id,
                    "uom": "cel",
                    "volume_percent": (feedstock.feedstock as IShortPiona).method.distribution.map(d => Number(d.id)),
                    "boiling_temperature": (feedstock.feedstock as IShortPiona).method.distribution.map(d => Number(d.value))
                },
                "rpdata_dict": { ...newObj[0], type: feedstock.unit === UNIT.WT ? "wt" : "vol" },
                "d1515_dict": {
                    "d1515": Number(feedstock.feedstock.components.filter(c => c.id === "d1515")?.[0]?.value)
                }
            }
        }
    }
    else if (feedstock.type === FEEDSTOCK_TYPES.DETAILED_PIONA) {
        let ret = {};
        let obj = feedstock.feedstock.components;
        let newObj = Object.keys(obj).map((id, value) => {
            let vals: Array<Number> = [];
            let key = obj[id].name + "_GROUP"
            vals.push(Number(obj[value]['N_Paraffins']));
            vals.push(Number(obj[value]['I_Paraffins']));
            vals.push(Number(obj[value]['Olefins']));
            vals.push(Number(obj[value]['Naphthenes']));
            vals.push(Number(obj[value]['Aromatics']));
            ret[key] = vals;
            return ret;
        });
        components = {
            DetailedPionaInput: {
                "INPUT": {
                    "TYPE": feedstock.unit === UNIT.WT ? "WEIGHT" : "VOLUME",
                    ...newObj[0]
                }
            }
        }
    }
    return components;
}

async function listenServiceBus(topicName, subscriptionName, sas_connection_string, sas_token, scenarioId) {
    // Please supply the connection string that you receive from my endpoint. Or you can actually construt the endpoint yourself  {FullyQualifiedNamespace, Endpoint, SharedAccessSignature}
    const sbClient = new ServiceBusClient(sas_connection_string);
    sleep(3000);
    const receiver = sbClient.createReceiver(topicName, subscriptionName);
    try {
        receiver.subscribe(
            {
                processMessage: async (args) => receiveMsgHandler(args, scenarioId, receiver, sbClient),
                processError: async (args) => serviceBusErrorHandler(args, scenarioId, sbClient, receiver)
            });
    }
    finally {

    }
}

async function receiveMsgHandler(brokeredMessage, scenarioId, receiver, sbClient) {
    let connectionClose: boolean = false;

    // Listening to all of the results...

    let response = brokeredMessage.body;
    // Stop receiving message from SB if user has stopped simulation
    if (response.status === TERMINATION_REASON.STOPPED_BY_USER) {
        receiver.close();
        sbClient.close();
        return;
    }

    // Update result data to redux
    connectionClose = UpdateResultToRedux(response.Output, response.Status, scenarioId);

    if (connectionClose) {
        receiver.close();
        sbClient.close();
        return;
    }
}

function UpdateResultToRedux(response, status, scenarioId: any) {
    let connectionClose: boolean = false;

    if (status === "DONE") {

        const Products: IProductPredictorOutputProduct[] = [];
        for (let key in response.Products) {
            Products.push({ name: key, value: response.Products[key] })
        }
        store.dispatch(setPPResult({ scenario_id: scenarioId, response: Products }));
        store.dispatch(setPPScenarioStatus({ scenario_id: scenarioId, status: SCENARIO_STATUS.COMPUTED }));
        store.dispatch(setPPScenarioCalculationStatus({ scenario_id: scenarioId, status: CALCULATION_STATUS.IDLE }));
        store.dispatch(setPPScenarioProgress({ scenario_id: scenarioId, status: SCENARIO_PROGRESS.VALIDATION }));
        store.dispatch(setProgress({ scenario_id: scenarioId, progress: Number(response.Progress) }))
        // store.dispatch(setIsExportable({ scenario_id: scenarioId, isTrueOrFalse: true }))  // TO BE USED IN FUTURE
        connectionClose = true;
    }
    else if (status === "IN_PROGRESS") {
        store.dispatch(setProgress({ scenario_id: scenarioId, progress: Number(response.Progress) }))
    }
    else if (Object.values(TERMINATION_REASON).includes(status)) {
        if (status !== TERMINATION_REASON.STOPPED_BY_USER) {
            const error: ErrorI = {
                date: new Date().toLocaleString(),
                source: "Error",
                message: `Error -  ${status} - ${response.ErrorMessage}`,
            };
            store.dispatch({ type: "ADD_ERROR", payload: { value: error } });
            store.dispatch(setPPScenarioStatus({ scenario_id: scenarioId, status: SCENARIO_STATUS.ERROR }));
            store.dispatch(setTerminationReason({ scenario_id: scenarioId, reason: TERMINATION_REASON.IDLE }));
            store.dispatch(setPPScenarioCalculationStatus({ scenario_id: scenarioId, status: CALCULATION_STATUS.IDLE }));
        }
        connectionClose = true;
    }
    else if (Object.values(ERROR_TYPE).includes(status)) {
        const error: ErrorI = {
            date: new Date().toLocaleString(),
            source: "Error",
            message: `Error -  ${status} - ${response.ErrorMessage}`,
        };
        store.dispatch({ type: "ADD_ERROR", payload: { value: error } });
        store.dispatch(setPPScenarioStatus({ scenario_id: scenarioId, status: SCENARIO_STATUS.ERROR }));
        store.dispatch(setTerminationReason({ scenario_id: scenarioId, reason: TERMINATION_REASON.IDLE }));
        store.dispatch(setPPScenarioCalculationStatus({ scenario_id: scenarioId, status: CALCULATION_STATUS.IDLE }));
        connectionClose = true;
    }
    return connectionClose;
}

async function serviceBusErrorHandler(args, scenarioId, receiver, sbClient) {
    // Possible error happen in this handler 
    // 1. The connection lost 
    // 2. The SAS token expired 
    // 3. The Topic is yet to create under the service bus 
    //
    //
    // Remarks : You need to manually close the connection as the library will not close it for you. 
    if (!receiver._isClosed && args.errorSource !== "complete") {
        console.log(`Error from source ${args.errorSource} occurred: `, args.error);

        const error: ErrorI = {
            date: new Date().toLocaleString(),
            source: "ServiceBus Error",
            message: `Error from source ${args.errorSource} occurred: ${args.error}`,
        };
        store.dispatch({ type: "ADD_ERROR", payload: { value: error } });
        store.dispatch(setPPScenarioStatus({ scenario_id: scenarioId, status: SCENARIO_STATUS.ERROR }));
        store.dispatch(setTerminationReason({ scenario_id: scenarioId, reason: TERMINATION_REASON.IDLE }));
        receiver.close();
        sbClient.close();
    }
    // GET THE SAS TOKEN From Result api again. 
}

function sleep(milliseconds) {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
}