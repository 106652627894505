import { combineReducers } from "redux";
import AppStateReducer, { AppState } from "./appStateReducer";
import COILReducer, { COILState } from "./coilReducer";
import authReducer, { authState } from "./authReducer";
import ErrorsReducer, { ErrorsState } from "./errorsReducer";
import TerminationReducer, { TerminationState } from "./TerminationReducer";
import scenarioSimulatorReducer, { IScenarioSimulatorSlice } from '../slices/scenarioSimulatorSlice'
import feedstockReducer, { IFeedstockSlice } from '../slices/feedstockSlice'
import fuelGasReducer, { IFuelGasSlice } from "../slices/fuelGasSlice";
import convectionsectionReducer, { IConvectionSectionSlice } from '../slices/convectionSectionSlice';
import furnanceReducer, { IFurnaceSlice } from "../slices/furnaceSlice";
import crackedGasReducer, { IcrackedGasSlice } from "../slices/crackedGasSlice";
import featuresReducer, { IFeaturesSlice } from "../slices/featuresSlice";
import productPredictorSlice, { IProductPredictorSlice } from "../slices/productPredictorSlice";
import adminPanelSlice, { IAdminPanelSlice } from "../slices/userRolesSlice";
import coilGroupSlice, { ICoilGroupSlice } from "../slices/coilGroupSlice";
import coilSlice, { ICoilSlice } from "../slices/coilSlice";
import cameraSlice, { IcameraSlice } from "../slices/cameraSlice";
import aggregateFunctionSlice, { IAggregateFunctionSlice } from "../slices/aggregateFunctionSlice";
import thermalImageSlice, { IthermalImageSlice } from "../slices/thermalImageSlice";
import { ICameraStatusSlice } from "../slices/cameraStatusSlice";
import cameraStatusSlice from "../slices/cameraStatusSlice";
import notificationReducer, { INotificationLogSlice } from "../slices/notificationLogSlice";
import configurationReducer, { IConfigurationSlice } from "../slices/configurationSlice";

/**
 * Root reducer combines all other reducers to one
 */

export const rootReducer = combineReducers({
  appState: AppStateReducer,
  coilState: COILReducer,
  // userRoleState: UserRolesReducer,
  authState: authReducer,
  errorsState: ErrorsReducer,
  terminationState: TerminationReducer,
  scenarioSimulator: scenarioSimulatorReducer,
  feedstock: feedstockReducer,
  fuelGas: fuelGasReducer,
  convectionsection: convectionsectionReducer,
  furnace: furnanceReducer,
  crackedGas: crackedGasReducer,
  features: featuresReducer,
  productPredictor: productPredictorSlice,
  adminPanel: adminPanelSlice,
  coil: coilSlice,
  camera: cameraSlice,
  coilGroup: coilGroupSlice,
  aggregateFunction: aggregateFunctionSlice,
  thermalImage: thermalImageSlice,
  cameraStatus: cameraStatusSlice,
  notificationState:notificationReducer,
  configuration: configurationReducer
});

export type RootState = {
  appState: AppState;
  coilState: COILState;
  // userRoleState: userRolesState;
  authState: authState;
  errorsState: ErrorsState;
  terminationState: TerminationState;
  scenarioSimulator: IScenarioSimulatorSlice;
  feedstock: IFeedstockSlice;
  fuelGas: IFuelGasSlice;
  convectionsection: IConvectionSectionSlice;
  furnace: IFurnaceSlice,
  crackedGas: IcrackedGasSlice,
  features: IFeaturesSlice,
  productPredictor: IProductPredictorSlice,
  adminPanel: IAdminPanelSlice
  coilGroup: ICoilGroupSlice,
  coil: ICoilSlice,
  camera: IcameraSlice,
  aggregateFunction: IAggregateFunctionSlice,
  thermalImage: IthermalImageSlice,
  cameraStatus: ICameraStatusSlice,
  notificationState: INotificationLogSlice,
  configuration: IConfigurationSlice
};